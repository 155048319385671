
/* Soft & Professional Palette */
  :root {
    --primary-color: #20C997; /* Teal Blue */
    --secondary-color: #ADB5BD; /* Cool Gray */
    --background-color: #F1F3F5; /* Alice Blue */
    --notification-color: #FFC107; /* Mellow Apricot */
    --active-user-count-color: #495057; /* Charcoal */
    --connection-status-connected: #20C997; /* Green */
    --connection-status-disconnected: #DC3545; /* Red */
    --primary-color-dark: #20c997; /* Teal Blue */
    --secondary-color-dark: #ced4da; /* Lighter Cool Gray */
    --background-color-dark: #343a40; /* Darker Background */
    --notification-color-dark: #ffc107; /* Mellow Apricot */
    --active-user-count-color-dark: #e9ecef; /* Lighter Text */
    --connection-status-connected-dark: #20c997; /* Green */
    --connection-status-disconnected-dark: #dc3545; /* Red */
    --tirtiary-color: #007BFF; /* Electric Blue */
  }

  .dark-mode {
    background-color: var(--background-color-dark);
    color: var(--active-user-count-color-dark);
    /* Other dark mode style adjustments */
  }
  
  .dark-mode .btn-primary {
    background-color: var(--primary-color-dark);
    /* Other button adjustments for dark mode */
  }
  
  body {
    background-color: var(--background-color);
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--background-color);
  }

  .btn-secondary {
    background-color: var(--notification-color);
    border-color: var(--notification-color);
  }
  
  .text-secondary {
    color: var(--secondary-color);
  }
  
  .notification {
    color: var(--notification-color);
  }
  
  .active-user-count {
    color: var(--active-user-count-color);
  }
  
  .connection-status.connected {
    color: var(--connection-status-connected);
  }
  
  .connection-status.disconnected {
    color: var(--connection-status-disconnected);
  }
  

  /* CUSTOM OVERRIDES */
  .form-control {
    color: var(--active-user-count-color) !important;
    text-transform: uppercase;
  }

  .form-control::placeholder {
    font-weight: bold;
    opacity: 0.5;
    color: var(--info-color);
  }

  .nav-item {
    margin-right: 0.25em !important;
  }

  .jumbotron {
    color: white;
    background-image: url("https://images.unsplash.com/photo-1438109491414-7198515b166b?q=80&fm=jpg&s=cbdabf7a79c087a0b060670a6d79726c");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }

  .handsbg {
    background-image: url("https://www.partypics.zip/assets/illustrated-hands.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: unset;
  }

  .handsbgcontain {
    background-image: url("https://www.partypics.zip/assets/illustrated-hands-flip.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: unset;
  }

  .handsbgfade {
    background-image: url("https://www.partypics.zip/assets/illustrated-hands.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: unset;
  }

  .herobg {
    background-image: url("https://images.unsplash.com/photo-1517456793572-1d8efd6dc135");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: unset;
  }

  .herobgfaded {
    background-image: url("https://partypics.zip/assets/hero-bg-faded.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: unset;
  }

  .herobg::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: unset;
    background-color: rgba(255,255,255,0.35);
  }

  /* .hero-section::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: unset;
    background-color: rgba(255,255,255,0.35);
  } */

  .handsbgfade::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(255,255,255,0.35);
  }

  footer::before {
    background-color: unset;
  }

  .wd-100 {
    width: 100% !important;
  }

  .alert-dismissible {
    padding-bottom: 0 !important;
  }

  .nsfw-blur {
    filter: blur(100px);
  }

  .fade-out {
    animation: fadeOutEffect 1s;
  }
  
  @keyframes fadeOutEffect {
    from {opacity: 1;}
    to {opacity: 0;}
  }