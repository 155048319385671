.navbar {
    padding: .5rem 1rem !important;
}

.retroBody {
    /* max-height: 80vh; */
    width: 100%;
}

nav, footer {
    /* display: none !important; */
}

.vintage {
    background-image: radial-gradient(ellipse, #0000, #0007), linear-gradient(0deg, #9725, #9725), url(https://i.ibb.co/vJt5HSh/noisy-texture-300x300-o10-d10-c-a82851-t1.png);
    background-size: auto, auto, auto, cover;
    background-repeat: repeat;
    background-position: center;
    filter: blur(0.0418em) saturate(0.87) contrast(1.1227) brightness(1.29);
}